import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withPrefix, graphql } from 'gatsby';
import Devops from '../../containers/services/Devops';

const styles = (theme) => ({
	container: {
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing.unit * 4,
			paddingRight: theme.spacing.unit * 4,
			paddingTop: theme.spacing.unit * 2,
			paddingBottom: theme.spacing.unit * 2
		  },
		  [theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing.unit * 22,
			paddingRight: theme.spacing.unit * 22,
			paddingTop: theme.spacing.unit * 5,
			paddingBottom: theme.spacing.unit * 5
		  }
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.mtitle
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.title
		}
	},
	paragraph: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.paragraph
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.paragraph
		}
	},
	space: {
		marginTop: theme.spacing.unit * 4
	},
	outerBox: {
		backgroundImage: `url(${withPrefix('/img/illustration-dev-ops.png')})`,
		position: 'relative',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		margin: 'auto',
		marginTop: '60px',
		[theme.breakpoints.up('sm')]: {
			width: '459.6px'
		},
		[theme.breakpoints.up('md')]: {
			width: '459.6px'
		}
	},
	block: {
		width: '100%',
		height: 'auto'
	}
});

const ServicesDevOpsPagePreviewTemplate = ({data,classes}) => {
  const { frontmatter } = data.markdownRemark;

  return (
		<Devops />
	)
}

export default withStyles(styles)(ServicesDevOpsPagePreviewTemplate);

export const pageQuery = graphql`
	query ServicesDevOpsPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				heading
				description
			}
		}
	}
`;
