import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import Grid from "@material-ui/core/Grid";
import { withPrefix, Link } from "gatsby";
import classnames from 'classnames';
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem"
    }
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  ul: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    fontSize: "22px"
  },
  anchor: {
    color: "rgba(0, 156, 219, 255)"
  },
  container: { marginTop: "44px", marginBottom: "44px" },
  box: {
    border: "1px solid #959595",
    padding: "30px 20px",
    marginTop: theme.spacing.unit * 3
  },
  boxHeading: {
    ...theme.typography.subheading,
    fontSize: "30px",
    color: "rgba(37, 54, 156, 255)",
    fontWeight: "400",
    textTransform: "uppercase"
  },
  boxText: { paddingLeft: "10px" },
  article: {
    ...theme.typography.article,
    paddingTop: theme.spacing.unit * 3,
    fontSize: "20px",
    color: "#777777",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
});

const technologies = [
  'Amazon Web Services (AWS), Microsoft Azure, Google Cloud, Rackspace',
  'Terraform, Puppet, Chef',
  'AWS CloudFormation, Kubernetes, Docker Swarm',
  'Jenkins, Bamboo, Maven',
  'JMeter, SonarQube, QASymphony, Cucumber, RSpec, Selenium',
  'Amazon EC2, Vagrant, Docker',
  'AWS CloudWatch, New Relic, AppDynamics',
  'Vault, Veracode, Threat Stack',
  'Jira, Confluence, HipChat, Slack, ServiceNow, VersionOne, Trello',
  'Git, Bitbucket, Mercurial, Perforce, Artifactory'
];

const implementation = [
  {
    title: 'Strategy',
    content: `We begin with an assessment your IT capabilities, maturity level
    and overall environment and produce a DevOps strategy roadmap to meet your
    goals.`
  },  {
    title: 'Technology and Toolchain selection',
    content: `We evaluate and recommend various automation and cloud-native
    technologies for your DevOps strategy.`
  },  {
    title: 'Development & testing',
    content: `We implement selected technologies seamlessly integrating them
    with your existing development, testing processes.`
  },  {
    title: 'Visibility',
    content: `We setup automated monitoring, reporting and notifications to
    keep track of the entire software development lifecycle.`
  },  {
    title: 'Operations',
    content: `After implementation, part of our team can, if requested, work as
    an extension of your own in-house team providing assistance or subject
    matter expertise or hands-on expertise and technical ownership as needed.`
  },
];

const Devops = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
    <TabBar fields={servicesFields} />
    <div className={classes.root}>
      <div className={classes.subheading}>
        DevOps
      </div>
      <div className={classes.paragraph}>
        Software development and delivery has evolved from large-scale,
        project-based implementations with one large monolithic delivery in
        the end to a continuous and incremental delivery of features and
        applications. Software is often updated and delivered in intervals
        measured in weeks, not months or years anymore.
      </div>
      <div className={classes.paragraph}>
        DevOps brings together software development (Dev) and software
        operations (Ops) to allow development and operations teams to work
        as a single unit over a highly automated software development
        lifecycle that runs from building to deploying code to testing to
        releasing to monitoring feedback, iteration after iteration. A number
        of tools have been developed and widely adopted to facilitate and
        automate continuous integration and delivery (CI/CD), automated
        testing, environment management, monitoring and notifications and so on.
      </div>

      <div className={classnames(classes.subheading, classes.container)}>
        OUR DEVOPS CONSULTING AND IMPLEMENTATION SERVICES
      </div>
      <Grid container justify="space-between">
        <Grid item sm={12} md={6}>
          {implementation.map(({title, content}, index) => (
            <div className={classes.article} key={index}>
              <b>{title}</b>
              <div className={classes.paragraph}>
                {content}
              </div>
            </div>
          ))}
        </Grid>
        <Grid item sm={12} md={5}>
          <div className={classes.box}>
            <div className={classes.boxHeading}>We speak DevOps</div>
            <div className={classes.paragraph}>
              We've worked with:
            </div>
            <Grid container alignItems="center" justify="space-between">
              {technologies.map((item, index) =>
                <Grid item xs={12} className={classes.paragraph} key={index}>
                  <img
                    src={withPrefix("icons/check.png")}
                    width="16"
                    height="17"
                  />
                  <span className={classes.boxText}>{item}</span>
                </Grid>
              )}
            </Grid>
            <div className={classes.paragraph}>
              Need a different technology?
              <Link to="/letstalk"> Contact us</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Devops);
